<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">基本資料</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-input label="姓名"
                                   :disabled="!edit_cust"
                                   v-model="customer.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="電話"
                                   :disabled="!edit_cust"
                                   v-model="customer.cel"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">
                      <mdb-btn class="mt-4" color="primary" size="sm" @click="edit_cust=true;" v-show="!edit_cust">
                        <i class="fa fa-edit"></i>
                        編輯
                      </mdb-btn>
                      <mdb-btn class="mt-4" size="sm" @click="saveData()" v-show="edit_cust">
                        <i class="fa fa-save"></i>
                        儲存
                      </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-row>
            <mdb-col md="5">
                <mdb-select label="顯示資料"
                            v-model="get_tabs"
                            @change="val=>tab_show=val"></mdb-select>
            </mdb-col>
        </mdb-row>
        <mdb-card v-show="tab_show==0"
                  :key="0">
            <mdb-card-header color="default">訂單紀錄</mdb-card-header>
            <mdb-card-body>
                <div class="rwd-table-dispersion">
                    <table class="table table-striped table-hover"
                           style="width:100%">
                        <thead>
                            <tr>
                                <th style="width:3rem">#</th>
                                <th style="width:8rem">訂單時間</th>
                                <th style="width:8rem">總金額</th>
                                <th style="width:5rem">狀態</th>
                                <th>備註</th>
                                <th style="width:5rem"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(o,ok) in orders_list"
                                :key="o.id">
                                <td data-title="#">{{ok+1}}</td>
                                <td data-title="訂單時間">{{o.day}}</td>
                                <td data-title="總金額">{{$numeral(o.total).format("0,0")}}</td>
                                <td data-title="狀態">
                                    <mdb-badge :color="o.status=='0'?'success':'warning'">
                                        {{orders_status_key_list[o.status]}}
                                    </mdb-badge>
                                </td>
                                <td data-title="備註"
                                    v-html="$nl2br(o.remark)"></td>
                                <td data-title="">
                                    <router-link class="btn btn-info btn-sm   text-nowrap"
                                                 :to="`/orders/view/${o.id}`">
                                        <i class="fa fa-eye"></i>
                                        &nbsp;檢視
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </mdb-card-body>
        </mdb-card>
        <mdb-card v-for="(cd,cdk) in customer_device_list"
                  :key="cd.id"
                  v-show="tab_show-1==cdk">
            <mdb-card-header color="default">
                {{
                repair_target_key_list[cd.id_repair_target]
                ? repair_target_key_list[cd.id_repair_target].name
                : "-"
                }}
                &nbsp;<br class="d-md-none">[{{cd.color}}] - 維修紀錄</mdb-card-header>
            <mdb-card-body>
                <div v-for="type in ['warranty','outwarranty']"
                     :key="type">
                    <h5>保固{{type=='warranty'?'內':'外'}}</h5>
                    <div class="rwd-table-dispersion">
                        <table class="table table-striped table-hover"
                               style="width:100%">
                            <thead>
                                <tr>
                                    <th style="width:3rem">#</th>
                                    <th style="width:20rem">維修項目</th>
                                    <th style="width:5rem">數量</th>
                                    <th style="width:8rem">金額</th>
                                    <th style="width:8rem">訂單日期</th>
                                    <th>保固</th>
                                </tr>
                            </thead>
                            <tbody v-if="orders_item_list[cd.id]">
                                <tr v-for="(oi,oik) in orders_item_list[cd.id][type]"
                                    :key="oi.id">
                                    <td>{{oik+1}}</td>
                                    <td>
                                        {{oi.product_name}}<br>
                                        <small>{{oi.product_code}}</small>
                                    </td>
                                    <td>{{oi.amount}}</td>
                                    <td>{{$numeral(oi.price).format('0,0')}}</td>
                                    <td>{{oi.day}}</td>
                                    <td>{{opt_warrantyList[oi.id_opt_warranty]?opt_warrantyList[oi.id_opt_warranty].name:''}}</td>
                                </tr>
                            </tbody>
                            <tr v-show="!orders_item_list[cd.id] || orders_item_list[cd.id][type].length==0 ">
                                <td colspan="5">
                                    無維修紀錄
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbBtn,
  mdbCol,
  mdbBadge,
  mdbInput,
  mdbSelect,
} from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbBtn,
    mdbBadge,
    mdbCol,
    mdbInput,
    mdbSelect,
  },
  data() {
    return {
      edit_cust: false,
      tab_show: 0,
      customer: "",
      customer_device_list: "",
      orders_list: [],
      orders_item_list: {},
      repair_target_key_list: {},
      orders_status_key_list: {},
      opt_warrantyList: {},
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    "$route.params.id_customer": {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  computed: {
    get_tabs() {
      let vue = this,
        output = [
          { text: "訂單紀錄", value: "0", selected: true },
          {
            text: "維修紀錄",
            value: null,
            disabled: true,
            optgroup: true,
          },
        ];
      vue.customer_device_list.forEach((item, index) => {
        output.push({
          text: `${
            vue.repair_target_key_list[item.id_repair_target]
              ? vue.repair_target_key_list[item.id_repair_target].name
              : "-"
          } [${item.color}]`,
          value: index + 1,
        });
      });
      return output;
    },
  },
  methods: {
    getData() {
      let vue = this;
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `customer/get_repair_log/?id_customer=${vue.$route.params.id_customer}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            res.data.opt_warrantyList.forEach((item) => {
              vue.opt_warrantyList[item.id] = item;
            });
            vue.customer = res.data.customer;
            vue.customer_device_list = res.data.customer_device_list;
            res.data.repair_target_list.forEach((item) => {
              vue.repair_target_key_list[item.id] = item;
            });
            res.data.orders_status_list.forEach((item) => {
              vue.orders_status_key_list[item.key] = item.value;
            });
            // 紀錄訂單日期(判斷保固用)
            let order_day = {};
            res.data.orders_list.forEach((item) => {
              vue.orders_list.push(item);
              order_day[item.id] = item.day;
            });
            let today = vue.$moment().format("YYYY-MM-DD");
            res.data.orders_item_list.forEach((item) => {
              if (!vue.orders_item_list[item.id_customer_device]) {
                vue.orders_item_list[item.id_customer_device] = {
                  warranty: [],
                  outwarranty: [],
                };
              }
              // 判斷保固
              let type = "outwarranty";
              let warranty = vue.opt_warrantyList[item.id_opt_warranty];
              if (warranty.days == -1) {
                // 永久保固
                type = "warranty";
              } else if (
                warranty.days > 0 &&
                vue
                  .$moment(order_day[item.id_orders])
                  .add(warranty.days, "day")
                  .format("YYYY-MM-DD") > today
              ) {
                // 有保固期限
                type = "warranty";
              }
              vue.orders_item_list[item.id_customer_device][type].push(
                Object.assign({}, item, { day: order_day[item.id_orders] })
              );
            });
          }
        });
    },
    saveData() {
      let vue = this;
      vue.$store
          .dispatch("post_form", {
            payload: {
              url: "orders/upd_customer",
              data: {
                data: JSON.stringify(vue.customer),
              },
            },
          })
          .then((res) => {
            vue.edit_cust = false;
            if (res.data.status == "ok") {
              vue.$swal
                .fire({
                  icon: "success",
                  title: "儲存完成!",
                  timer: 1500,
                  showConfirmButton: false,
                });
            }
          });
    }
  },
};
</script>